import moment from "moment";

export const utilMixin = {
  methods: {
    formatFloat(value, digits = 2, hasSeparation = true) {
      if (value != null) {
        if (hasSeparation) {
          return value
            .toFixed(digits)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'");
        }
        return value.toFixed(digits);
      }
      return 0;
    },
    formatInt(value) {
      if (value != 0) {
        return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'");
      }
      return 0;
    },
    formatDateTime(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    getDateTimeDiff(startDate, endDate) {
      let diff_ms = moment(endDate).diff(startDate);
      let delta = moment.duration(diff_ms);
      return delta;
    },
    formatDataTimeWithTime(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    getDateTimeDiffFromNow(endDate) {
      let now = moment(new Date());
      return this.getDateTimeDiff(now, endDate);
    },
    /* Locality */
    getLocality(localityId) {
      if (this.getLocalities === null) {
        return null;
      }
      const localityElem = this.getLocalities.find(
        locality => localityId === locality.id
      );
      if (localityElem !== undefined) {
        return localityElem;
      }
      return null;
    },
    getLocalityNameForId(localityId) {
      const locality = this.getLocality(localityId);
      if (locality !== null) {
        return this.formatLocality(locality);
      }
      return null;
    },
    getLocalityIdForName(localityName) {
      if (this.getLocalities === null) {
        return null;
      }
      const localityElem = this.getLocalities.find(
        locality => localityName === this.formatLocality(locality)
      );
      if (localityElem !== undefined) {
        return localityElem.id;
      }
      return null;
    },
    formatLocality(locality) {
      return locality.z + " " + locality.p;
    },
    /* Invoices */
    getInvoiceStatusType(invoiceStatus) {
      if (invoiceStatus === "OPEN") {
        return "warning";
      } else if (invoiceStatus === "PAID") {
        return "success";
      } else {
        return "danger";
      }
    }
  }
};
